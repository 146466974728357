export interface IProductCategory {
  id: string
  name: string
  parentId: string
  lineage: string[]
  hasChildren: boolean
}

export class ProductCategory implements IProductCategory {
  public id: string = ''
  public name: string = ''
  public parentId: string =''
  public lineage: string[] = []
  public hasChildren: boolean = true

  constructor() {}

  get lineageString() {
    return this.lineage.join(' > ')
  }

  get fullName() {
    const lineageString = this.lineageString
    return lineageString
      ? `${lineageString} > ${this.name}`
      : this.name
  }
}
