import {
  mdiChartLineVariant,
  mdiViewDashboard
} from '@mdi/js'

export default [
  { title: 'Application Admin' },
  { title: 'Dashboard', icon: mdiViewDashboard, nav: 'admin-dashboard' },
  { title: 'Metrics', icon: mdiChartLineVariant, nav: 'admin-metrics' }
]
