import { UAParser } from 'ua-parser-js'

const uaParser = new UAParser()
const engine = uaParser.getEngine().name
const browser = uaParser.getBrowser().name

const navigatorStub: any = navigator || window.navigator

export default {
  getUserMedia: navigatorStub.getUserMedia
    || (navigatorStub.mediaDevices && navigatorStub.mediaDevices.getUserMedia)
    || navigatorStub.webkitGetUserMedia
    || navigatorStub.mozGetUserMedia
}
