import { db } from '@/plugins/firebase'
import {collection, query, where, onSnapshot, Firestore, DocumentSnapshot} from 'firebase/firestore'
import { BaseRepository } from '../Base/BaseRepository'
import {User} from './User'
import {UserMapper} from './UserMapper'

export class UserRepository extends BaseRepository<User> {
    protected collectionName = 'user'
    protected mapper = new UserMapper()

    constructor(firestore: Firestore) {
        super(firestore)

    }

    async findAllExceptUserIds(userIds: string[]): Promise<User[]> {
        const users = await this.query((collection, qb) =>
            qb.where('id', 'not-in', userIds).build(),
        )
        return users
    }

    async findAllByUserIds(userIds: string[]): Promise<User[]> {
        const users = await this.query((collection, qb) =>
            qb.where('id', 'in', userIds).build(),
        )
        return users
    }

    async findByUserId(userId: string): Promise<User | null> {
        const users = await this.query((collection, qb) =>
            qb.where('id', '==', userId).build(),
        )
        return users[0] || null
    }

    watchAllByUserIds(userIds: string[], onUpdate: (users: User[]) => void) {
        const userRef = collection(db, this.collectionName)
        const q = query(userRef, where('id', 'in', userIds))
        const unsub = onSnapshot(q, (snapshot) => {
            const mappedUsers = snapshot.docs.map(doc => 
                this.mapper.fromFirestore({
                    id: doc.id,
                    data: () => doc.data(),
                    exists: () => true
                } as DocumentSnapshot))
            onUpdate(mappedUsers)
        }, (error) => {
            console.error('Error in user watcher:', error)
        })
        
        return { unsub }

    }

    async list(order?: [string, 'asc' | 'desc'][]): Promise<User[]> {
        if (!order?.length) return this.findAll()

        return this.query((collection, qb) => {
            return qb.orderBy(order[0][0], order[0][1]).build()
        })
    }

    async findByApiKey(apiKey: string): Promise<User | null> {
        const users = await this.query((collection, qb) =>
            qb.where('apiKey', '==', apiKey).build(),
        )
        return users[0] || null
    }

    async findByPhone(phoneNumber: string): Promise<User | null> {
        const users = await this.query((collection, qb) =>
            qb.where('phoneNumber', '==', phoneNumber).build(),
        )
        return users[0] || null
    }

    async findByEmail(email: string): Promise<User | null> {
        const users = await this.query((collection, qb) =>
            qb.where('email', '==', email).build(),
        )
        return users[0] || null
    }

    async findByCompanyId(companyId: string): Promise<User[]> {
        return this.query((collection, qb) =>
            qb.where('companyId', '==', companyId).build(),
        )
    }

    async save(user: User): Promise<User> {
        return this.upsert(user)
    }
} 
