import {DocumentSnapshot} from "firebase/firestore";
import {AddressMapper, BaseMapper, Company, CompanyStatusEnum, CompanyTypeEnum, ICollectionMapper} from '@/firestore'

export class CompanyMapper extends BaseMapper<Company> implements ICollectionMapper<Company> {
    fromFirestore(snapshot: DocumentSnapshot): Company {
        const data = this.getSnapshotData(snapshot)
        if (!data) throw new Error('No data in DocumentSnapshot')

        const company = new Company(data.name, data.type as CompanyTypeEnum)
        if (data.address) {
            company.address = (new AddressMapper()).map(data.address)
        }
        company.adminCompanyId = data.adminCompanyId
        company.admins = data.admins || []
        company.features = data.features || []
        company.id = snapshot.id
        company.identifier = data.identifier
        company.preferred = data.preferred
        company.productTypes = data.productTypes
        company.status = data.status as CompanyStatusEnum
        company.users = data.users || []
        company.userRoles = data.userRoles
        company.externalIds = data.externalIds || {}
        // company.createdAt = data.createdAt ? new Date(data.createdAt) : undefined
        // company.updatedAt = data.updatedAt ? new Date(data.updatedAt) : undefined
        // company.subscriptionId = data.subscriptionId
        // company.restaurantLimit = data.restaurantLimit

        return company
    }

    toFirestore(company: Company): Record<string, any> {
        return {
            address: company.address ? (new AddressMapper()).serialize(company.address) : null,
            adminCompanyId: company.adminCompanyId,
            admins: company.admins,
            features: company.features,
            id: company.id,
            identifier: company.identifier,
            name: company.name,
            preferred: company.preferred,
            productTypes: company.productTypes,
            status: company.status,
            type: company.type,
            userRoles: company.userRoles,
            users: company.users,
            externalIds: company.externalIds,
            // createdAt: company.createdAt,
            // updatedAt: new Date(),
            // subscriptionId: company.subscriptionId,
            // restaurantLimit: company.restaurantLimit,
        }
    }

    map(data: any): Company {
        return this.fromFirestore({id: data.id, data: () => data} as DocumentSnapshot)
    }

    serialize(object: Company): any {
        return this.toFirestore(object)
    }

    getDocumentId(company: Company): string | undefined {
        return company.id
    }
}
