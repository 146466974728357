import { App } from 'vue'
import numeral from 'numeral'
import 'vue'

export const filtersPlugin = {
  install: (app: App) => {
    app.config.globalProperties.$filters = {
      capitalize(value: string) {
        if (!value) return ''
        value = value.toString().toLowerCase()
        return value.charAt(0).toUpperCase() + value.slice(1)
      },

      phone(phone: string) {
        if (!phone) return ''
        return phone.replace(/[^0-9]/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      },

      dollar(amount: number) {
        if (isNaN(amount)) return '' + amount
        return numeral(amount).format('$0,0.00')
      },

      numeral(value: number, format: string) {
        if (isNaN(value)) return '' + value
        return numeral(value).format(format)
      }
    }
  }
}

// Type augmentation
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $filters: {
      capitalize(value: string): string
      phone(phone: string): string
      dollar(amount: number): string
      numeral(value: number, format: string): string
    }
  }
} 
