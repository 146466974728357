import type {DocumentSnapshot} from 'firebase/firestore'

export abstract class BaseMapper<T> {
    abstract fromFirestore(snapshot: DocumentSnapshot): T

    abstract toFirestore(entity: T): Record<string, any>

    abstract getDocumentId(entity: T): string | undefined

    protected getSnapshotData(snapshot: DocumentSnapshot): Record<string, any> {
        return snapshot.data() as Record<string, any>
    }
} 
