import {
  mdiAccountMultiple,
  mdiClipboardText,
  mdiCurrencyUsd,
  mdiPackageVariantClosed,
  mdiStorefront,
  mdiBottleWine,
  mdiNumeric,
  mdiStar,
  mdiFileDocument,
  mdiCogs,
  mdiFileExport
} from '@mdi/js'

export const home = 'home'
export const vendors = 'vendor-list'

export default [
  { title: 'Restaurant' },
  { title: 'Orders', icon: mdiClipboardText, nav: 'order-list' },
  { title: 'Receiving', icon: mdiPackageVariantClosed, nav: 'receiving-list' },
  { title: 'Settings' },
  { title: 'Vendors', icon: mdiStorefront, nav: 'vendor-list' },
  { title: 'Users', icon: mdiAccountMultiple, nav: 'user-list' },
  { title: 'Liquor License', icon: mdiBottleWine, nav: 'liquor-license' },
  { title: 'Par Quantities', icon: mdiNumeric, nav: 'par-edit' },
  { title: 'Favorites', icon: mdiStar, nav: 'favorite-list-detail' },
  { title: 'EOD Report', icon: mdiFileDocument, nav: 'report-eod-detail' },
  { title: 'Integrations' },
  { title: 'Accounting', icon: mdiCurrencyUsd, nav: 'accounting-tabs' },
  { title: 'Quickbooks Settings', icon: mdiCogs, nav: 'quickbooks-settings' },
  { title: 'Quickbooks Export', icon: mdiFileExport, nav: 'quickbooks-export' }
]
