import {v4 as uuid} from 'uuid'
import {AdminRoleEnum} from './AdminRoleEnum'
import {UserRoleEnum} from './UserRoleEnum'
import {UserStatusEnum} from './UserStatusEnum'
import {ICollectionDocument} from "../Firestore/ICollectionDocument";

export interface ICompanyRoles {
    [companyId: string]: UserRoleEnum
}

export interface IUser extends ICollectionDocument {
    id: string
    apiKey: string
    email: string
    tz: string
    companyId: string
    companyRoles?: ICompanyRoles
    name: string
    status: UserStatusEnum
    phoneNumber: string
    admin?: AdminRoleEnum
}

export class User implements IUser {
    public apiKey: string = uuid()
    public tz: string = 'America/New_York'
    public name: string = ''
    public status: UserStatusEnum = UserStatusEnum.ACTIVE
    public email: string = ''
    public companyRoles?: ICompanyRoles
    public admin?: AdminRoleEnum

    constructor(public id: string, public companyId: string, public phoneNumber: string) {
    }

    public get displayName() {
        return this.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
    }
}
