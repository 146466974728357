export interface IAddress
{
  id: string
  address?: string
  company?: string
  city?: string
  state?: string
  street?: string
  subpremise?: string
  name?: string
  country?: string
  county?: string
  district?: string
  streetNumber?: string
  postalCode?: string
  lat?: string
  lng?: string
  placeId?: string
  cardName?: string
  streetAddress?: string
  displayName?: string
}

export class Address implements IAddress {
  id: string = ''
  address?: string
  city?: string
  state?: string
  street?: string
  subpremise?: string
  name?: string
  country?: string = 'United States'
  county?: string
  district?: string
  streetNumber?: string
  postalCode?: string
  lat?: string
  lng?: string
  placeId?: string

  get cardName() {
    return this.city && this.state ? `${this.city}, ${this.state}` : ''
  }

  get streetAddress() {
    return `${this.streetNumber || ''} ${this.street || ''}`
      .trim()
      .replace(/^,+|,+$/gm, '')
  }
  set streetAddress(sa: string) {
    if(!sa) {
      return
    }

    const pieces = sa.trim().split(/ (.+)/)
    this.streetNumber = pieces[0]
    this.street = pieces[1]
  }

  get displayName() {
    if (this.address) {
      return this.name && !this.address.includes(this.name)
        ? `${this.name}: ${this.address}`
        : this.address
    }

    return [
      `${this.streetNumber || ''} ${this.street || ''}`.trim(),
      this.city,
      `${this.state || ''} ${this.postalCode || ''}`.trim(),
    ].filter(x => !!x).join(', ')
  }

  static isAddress(address: any): address is Address {
    if (address instanceof Address) {
      return true;
    }

    if (typeof address.id !== 'string') { return false; }
    if (address.address && typeof address.address !== 'string') { return false; }
    if (address.city && typeof address.city !== 'string') { return false; }
    if (address.state && typeof address.state !== 'string') { return false; }
    if (address.street && typeof address.street !== 'string') { return false; }
    if (address.subpremise && typeof address.subpremise !== 'string') { return false; }
    if (address.name && typeof address.name !== 'string') { return false; }
    if (address.country && typeof address.country !== 'string') { return false; }
    if (address.county && typeof address.county !== 'string') { return false; }
    if (address.district && typeof address.district !== 'string') { return false; }
    if (address.streetNumber && typeof address.streetNumber !== 'string') { return false; }
    if (address.postalCode && typeof address.postalCode !== 'string') { return false; }
    if (address.lat && typeof address.lat !== 'string') { return false; }
    if (address.lng && typeof address.lng !== 'string') { return false; }
    if (address.placeId && typeof address.placeId !== 'string') { return false; }

    return true;
  }
}
