import { App } from 'vue'
import { useStore } from '@/store'
import { FirebaseApp, initializeApp } from 'firebase/app'
import { getAuth, getIdTokenResult, onAuthStateChanged, onIdTokenChanged, Auth, connectAuthEmulator } from 'firebase/auth'
import { 
  connectFirestoreEmulator, 
  Firestore, 
  getFirestore, 
  onSnapshot, 
} from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import * as Sentry from '@sentry/vue'
import {
  Company,
  CompanyRepository,
  CompanyTypeEnum,
  UserRepository
} from '@/firestore'

let firebase: FirebaseApp;
let auth: Auth;
let db: Firestore;

// Create a Vue plugin to make Firebase available throughout the app
export const firebasePlugin = {
  install: async (app: App) => {
    const config = await (await fetch('/__/firebase/init.json')).json();
    const firebaseApp = initializeApp(config)
    const firestore = getFirestore(firebaseApp)
    const functions = getFunctions(firebaseApp)

    firebase = firebaseApp
    db = firestore
    auth = getAuth(firebaseApp)

    if (location.hostname === 'localhost') {
      try {
        // connectAuthEmulator(auth, 'http://localhost:9099')
        connectFirestoreEmulator(firestore, 'localhost', 8080)
        connectFunctionsEmulator(functions, 'localhost', 5001)
        console.log('Connected to Firebase emulators')
      } catch (error) {
        console.error('Failed to connect to Firebase emulators:', error)
      }
    }

    try {
      app.config.globalProperties.$firebase = Promise.resolve(firebase)
      app.config.globalProperties.$firebaseConfig = config

      initFirebaseAuth()
    } catch (error) {
      console.error('Failed to initialize Firebase:', error)
    }
  }
}

function initFirebaseAuth() {
  const store = useStore()
  // Set auth to not ready while we're initializing
  store.setAuthReady(false)
  const sentryFirebaseScope = new Sentry.Scope()
  sentryFirebaseScope.setLevel('debug')

  // Listen for ID token changes
  onIdTokenChanged(auth, async (user) => {
    if (!user) {
      Sentry.captureMessage('Firebase Token Changed: token removed', sentryFirebaseScope)
    }
    const idToken = user ? (await getIdTokenResult(user)).token : null
    store.updateIdToken(idToken)
  })

  // Listen for auth state changes
  onAuthStateChanged(auth, async (user) => {
    const userRepository = new UserRepository(db)
    const companyRepository = new CompanyRepository(db)
    const idTokenResult = user ? await getIdTokenResult(user) : null
    const userClaim = idTokenResult ? idTokenResult.claims : null
    const idToken = idTokenResult ? idTokenResult.token : null

    if (user) {
      const sentryUser = {
        id: user.uid,
        email: user.email || undefined,
        phoneNumber: user.phoneNumber || undefined
      } as Sentry.User
      if (userClaim && userClaim.name) {
        sentryUser.username = userClaim.name as string
      }

      const userFromDb = await userRepository.findById(user.uid)

      if (userFromDb && userFromDb.admin && userClaim) {
        userClaim.admin = userFromDb.admin
      }

      Sentry.setUser(sentryUser)
    } else {
      Sentry.captureMessage('Firebase Auth State Changed: auth removed', sentryFirebaseScope)
    }

    let companies = user
      ? (await companyRepository.findByUser(user.uid))
        .reduce((acc, cur) => ({
          ...acc,
          [cur.id]: cur
        }), {} as { [companyId: string]: Company })
      : null

    if (companies) {
      const adminCompanies = Object.values(companies)
        .filter((company) => company.type === CompanyTypeEnum.ADMIN)
      for (const company of adminCompanies) {
        const restaurants = await companyRepository.findByAdminCompany(company.id)
        restaurants.forEach((restaurant) => {
          if (companies) {
            companies[restaurant.id] = restaurant
          }
        })
      }
    }

    if (userClaim && userClaim.admin) {
      const storeString = localStorage.getItem('store')
      if (storeString) {
        const storeData = JSON.parse(storeString)

        if (storeData && storeData.companies) {
          if (!companies) {
            companies = {}
          }

          for (const company of Object.values(storeData.companies) as Company[]) {
            if (!companies[company.id]) {
              companies[company.id] = company
            }
          }
        }
      }
    }

    store.updateUser(user)
    store.updateUserClaim(userClaim)
    store.updateIdToken(idToken)
    store.updateCompanies(companies)
    await store.setUserCompany()
    // This will set authReady to true
    store.setLogin()
  })
}

// Type augmentation for Vue
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $firebase: Promise<FirebaseApp>
    $firebaseConfig: {
      projectId: string
      databaseURL: string
      storageBucket: string
      locationId: string
      apiKey: string
      authDomain: string
      messagingSenderId: string
    }
  }
}

export { firebase, auth, db, onSnapshot }
