import {Address, IAddress} from './Address'
import {ICollectionMapper} from '../Firestore/ICollectionMapper'

export class AddressMapper implements ICollectionMapper<Address> {
  map(data: IAddress): Address {
    const address = new Address()

    address.id = data.id || address.id
    address.city = data.city
    address.state = data.state
    address.name = data.name
    address.street = data.street
    address.country = data.country
    address.county = data.county
    address.district = data.district
    address.streetNumber = data.streetNumber
    address.postalCode = data.postalCode
    address.lat = data.lat
    address.lng = data.lng

    return address
  }

  serialize(object: Address): IAddress {
    return JSON.parse(JSON.stringify(object))
  }
}
