<template>
  <div>
    <v-select v-if="userCompany.type !== 'vendor'"
              ref="select"
              label="Location"
              :items="restaurants"
              :item-title="restaurantDisplayText"
              item-value="id"
              return-object
              :menu-props="{maxHeight: '340'}"
              :rules="[(v) => !!v || 'Please Select a Restaurant']"
              @update:model-value="updateEntry"
              v-model="currentRestaurant">
      <template v-slot:prepend-item>
        <v-list-item>
          <v-btn class="mb-2" small color="primary" @click="addRestaurant">Add Restaurant</v-btn>
        </v-list-item>
        <v-divider/>
      </template>
    </v-select>
    <div v-else>
      <h3>{{ currentRestaurantName() }}</h3>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, ref, watch} from 'vue'
import {useRouter} from 'vue-router'
import {CompanyByKey, useStore} from '@/store'
import {Company} from '@/firestore'
import {navigateToCompanyHome} from '@/router'

const router = useRouter()
const store = useStore()
const select = ref<HTMLElement>()

const restaurants = ref<Company[]>([]);
const currentRestaurant = defineModel<Company>()
// Add a computed property with null check
const userCompany = computed(() => store.userCompany || { type: '' })

const currentRestaurantValue = computed({
  get: () => {
    return currentRestaurant.value
  },
  set: (c: Company) => {
    currentRestaurant.value = c
  }
})

// Watch userCompanies changes
watch(() => store.companies, () => {
  // Add null check to prevent errors when user is not logged in
  restaurants.value = store.companies ? Object.values(store.companies as CompanyByKey) as Company[] : []
}, {immediate: true, deep: true})

// Watch userCompany changes
watch(() => store.userCompany, () => {
  // Skip if userCompany doesn't exist (user not logged in)
  if (!store.userCompany || !store.userCompany.id) return;
  
  currentRestaurantValue.value = restaurants.value.find(
      ({id}) => id === store.userCompany.id
  ) as Company
}, {immediate: true})

function addRestaurant() {
  if (select.value) {
    select.value.blur()
  }
  if (router.currentRoute.value.name === 'restaurant-edit') {
    router.push({name: 'restaurant-edit', params: {id: 'create'}})
    router.go(0)
  } else {
    router.push({name: 'restaurant-edit', params: {id: 'create'}})
  }
}

function restaurantDisplayText(restaurant: Company) {
  let displayText = restaurant.name
  if (restaurant.identifier) {
    displayText += ` (${restaurant.identifier})`
  } else if (restaurant.address?.state) {
    displayText += ` (${restaurant.address.state})`
  }
  return displayText
}

async function updateEntry(company: Company) {
  const [currentCompany] = await store.setUserCompany(company)
  currentRestaurantValue.value = currentCompany
  navigateToCompanyHome(currentCompany)
}

function currentRestaurantName() {
  return currentRestaurant.value?.name || ''
}
</script>
