import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {useStore} from '@/store'
import {AdminRoleEnum, CompanyTypeEnum, UserRoleEnum} from '@/firestore'
import {getHome} from './services/navigation.service'
import {getAuth, signInWithCustomToken} from 'firebase/auth'
import {firebase} from './plugins/firebase'

const routes: RouteRecordRaw[] = [
  {
    path: '/auth',
    name: 'auth',
    component: () => import('./views/Auth.vue'),
    meta: {
      layout: 'no-header',
      title: 'Authentication',
      skipAuthCheck: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/Login.vue'),
    meta: {
      layout: 'no-header',
      title: 'Login'
    }
  },
  {
    path: '/',
    name: 'home',
    redirect: {name: 'order-list'},
    meta: {
      requiresAuth: true,
      title: 'Dashboard',
      companyIndependent: true,
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/error',
    name: 'page-not-found',
    component: () => import('./views/Error.vue'),
    meta: {
      requiresAuth: true,
      title: 'Error'
    }
  },
  {
    path: '/orders',
    name: 'order-list',
    component: () => import('./views/Restaurant/Order/List.vue'),
    meta: {
      requiresAuth: true,
      title: 'Orders',
      roles: [
        UserRoleEnum.ACCOUNTANT,
        UserRoleEnum.USER,
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ],
      companyIndependent: true
    }
  },
  {
    path: '/orders/:id',
    name: 'order-details',
    component: () => import('./views/Restaurant/Order/Detail.vue'),
    meta: {
      requiresAuth: true,
      title: 'Order Information',
      roles: [
        UserRoleEnum.ACCOUNTANT,
        UserRoleEnum.USER,
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/orders/:id/edit',
    name: 'order-edit',
    component: () => import('./views/Restaurant/Order/Edit.vue'),
    meta: {
      requiresAuth: true,
      title: 'Order Edit',
      roles: [
        UserRoleEnum.USER,
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/vendors',
    name: 'vendor-list',
    component: () => import('./views/Restaurant/Vendor/List.vue'),
    meta: {
      requiresAuth: true,
      title: 'Vendors',
      roles: [
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ],
      companyIndependent: true
    }
  },
  {
      path: '/vendors/add',
      name: 'vendor-add',
      component: () => import('./views/Restaurant/Vendor/AddVendor.vue'),
      meta: {
        requiresAuth: true,
        title: 'Add Vendor',
        roles: [
          UserRoleEnum.MANAGER,
          UserRoleEnum.CHEF,
          UserRoleEnum.GENERAL_MANAGER,
          UserRoleEnum.ADMIN
        ],
        companyTypes: [
          CompanyTypeEnum.RESTAURANT
        ],
        companyIndependent: true
      }
    },
  {
    path: '/vendors/:id',
    name: 'vendor-details',
    component: () => import('./views/Restaurant/Vendor/Detail.vue'),
    meta: {
      requiresAuth: true,
      title: 'Vendor Information',
      roles: [
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/vendors/:id/catalog',
    name: 'vendor-catalog',
    component: () => import('./views/Restaurant/Vendor/VendorCatalog.vue'),
    meta: {
      requiresAuth: true,
      title: 'Vendor Catalog',
      roles: [
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/vendors/:id/edit',
    name: 'vendor-edit',
    component: () => import('./views/Restaurant/Vendor/Edit.vue'),
    meta: {
      requiresAuth: true,
      title: 'Vendor Edit',
      roles: [
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/vendorRep/:id/edit',
    name: 'vendor-rep-edit',
    component: () => import('./views/Restaurant/PriceList/Edit.vue'),
    meta: {
      requiresAuth: true,
      title: 'Vendor Rep Edit',
      roles: [
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/vendors/:id/reminders',
    name: 'reminder-management',
    component: () => import('./views/Restaurant/Vendor/Reminders.vue'),
    meta: {
      requiresAuth: true,
      title: 'Reminder Management',
      roles: [
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/receiving',
    name: 'receiving-list',
    component: () => import('./views/Restaurant/Receiving/List.vue'),
    meta: {
      requiresAuth: true,
      title: 'Receiving',
      roles: [
        UserRoleEnum.ACCOUNTANT,
        UserRoleEnum.USER,
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ],
      companyIndependent: true
    }
  },
  {
    path: '/receiving/:id',
    name: 'receiving-details',
    component: () => import('./views/Restaurant/Receiving/Detail.vue'),
    meta: {
      requiresAuth: true,
      title: 'Receiving Details',
      roles: [
        UserRoleEnum.ACCOUNTANT,
        UserRoleEnum.USER,
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/users',
    name: 'user-list',
    component: () => import('./views/Users/List.vue'),
    meta: {
      requiresAuth: true,
      title: 'Users',
      roles: [
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT,
        CompanyTypeEnum.ADMIN
      ],
      companyIndependent: true
    }
  },
  {
    path: '/users/:id/edit',
    name: 'user-edit',
    component: () => import('./views/Users/Edit.vue'),
    meta: {
      requiresAuth: true,
      title: 'User Details',
      roles: [
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT,
        CompanyTypeEnum.ADMIN
      ]
    }
  },
  {
    path: '/vendor/orders',
    name: 'vendor-order-list',
    component: () => import('./views/Vendor/Order/List.vue'),
    meta: {
      requiresAuth: true,
      title: 'Orders',
      roles: [
        UserRoleEnum.USER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.VENDOR
      ],
      companyIndependent: true
    }
  },
  {
    path: '/vendor/orders/:id',
    name: 'vendor-order-details',
    component: () => import('./views/Vendor/Order/Detail.vue'),
    meta: {
      requiresAuth: true,
      title: 'Order Information',
      roles: [
        UserRoleEnum.USER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.VENDOR
      ]
    }
  },
  {
    path: '/vendor/orders/:id/edit',
    name: 'vendor-order-edit',
    component: () => import('./views/Vendor/Order/Edit.vue'),
    meta: {
      requiresAuth: true,
      title: 'Order Edit',
      roles: [
        UserRoleEnum.USER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.VENDOR
      ]
    }
  },
  {
    path: '/restaurant/:id/edit',
    name: 'restaurant-edit',
    component: () => import('./views/Restaurant/Restaurant/Edit.vue'),
    meta: {
      requiresAuth: true,
      title: 'Restaurant Edit',
      roles: [
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT,
        CompanyTypeEnum.ADMIN
      ]
    }
  },
  {
    path: '/restaurant/restaurants',
    name: 'restaurant-list',
    component: () => import('./views/Restaurant/Restaurant/List.vue'),
    meta: {
      requiresAuth: true,
      title: 'Restaurants',
      companyTypes: [
        CompanyTypeEnum.ADMIN
      ]
    }
  },
  {
    path: '/restaurant/operations',
    name: 'overview-list',
    component: () => import('./views/Admin/Operations/Overview.vue'),
    meta: {
      requiresAuth: true,
      title: 'Overview',
      companyTypes: [
        CompanyTypeEnum.ADMIN
      ]
    }
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('./views/Onboarding/Onboarding.vue'),
    meta: {
      layout: 'no-header',
      title: 'Onboarding'
    }
  },
  {
    path: '/onboarding/verify-admin',
    name: 'verify-admin',
    component: () => import('./views/Onboarding/VerifyAdminLogin.vue'),
    meta: {
      layout: 'no-header',
      title: 'Onboarding'
    }
  },
  {
    path: '/vendor/operations',
    name: 'vendor-overview-list',
    component: () => import('./views/Vendor/Operations/Overview.vue'),
    meta: {
      requiresAuth: true,
      title: 'Overview',
      companyTypes: [
        CompanyTypeEnum.VENDOR
      ]
    }
  },
  {
    path: '/restaurant/license',
    name: 'liquor-license',
    component: () => import('./views/Restaurant/License/LiquorLicense.vue'),
    meta: {
      requiresAuth: true,
      title: 'Liquor License',
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/par/edit',
    name: 'par-edit',
    component: () => import('./views/Restaurant/Par/Edit.vue'),
    meta: {
      requiresAuth: true,
      title: 'Par Quantities',
      roles: [
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/par/orders/:id/edit',
    name: 'par-order-edit',
    component: () => import('./views/Restaurant/Par/Order.vue'),
    meta: {
      requiresAuth: true,
      title: 'Par Order Edit',
      roles: [
        UserRoleEnum.USER,
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/favorites/edit',
    name: 'favorite-list-edit',
    component: () => import('./views/Restaurant/FavoriteList/Edit.vue'),
    meta: {
      requiresAuth: true,
      title: 'Favorites Edit',
      roles: [
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/favorites',
    name: 'favorite-list-detail',
    component: () => import('./views/Restaurant/FavoriteList/Detail.vue'),
    meta: {
      requiresAuth: true,
      title: 'Favorites Detail',
      roles: [
        UserRoleEnum.USER,
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/report/eod/detail/:date?',
    name: 'report-eod-detail',
    component: () => import('./views/Restaurant/ReportEod/Detail.vue'),
    meta: {
      requiresAuth: true,
      title: 'End Of Day Report View',
      roles: [
        UserRoleEnum.USER,
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import('./views/ApplicationAdmin/AdminDashboard.vue'),
    meta: {
      requiresAuth: true,
      title: 'Admin Dashboard',
      roles: [
        AdminRoleEnum.APPLICATION_ADMIN,
        AdminRoleEnum.READ_ONLY_ADMIN
      ]
    }
  },
  {
    path: '/admin/metrics',
    name: 'admin-metrics',
    component: () => import('./views/ApplicationAdmin/AdminMetrics.vue'),
    meta: {
      requiresAuth: true,
      title: 'Admin Metrics',
      roles: [
        AdminRoleEnum.APPLICATION_ADMIN,
        AdminRoleEnum.READ_ONLY_ADMIN
      ]
    }
  },
  {
    path: '/quickbooks/settings',
    name: 'quickbooks-settings',
    component: () => import('./views/Restaurant/Quickbooks/QuickbooksSettings.vue'),
    meta: {
      requiresAuth: true,
      title: 'Quickbooks Settings',
      roles: [
        UserRoleEnum.USER,
        UserRoleEnum.ACCOUNTANT,
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  },
  {
    path: '/quickbooks/export',
    name: 'quickbooks-export',
    component: () => import('./views/Restaurant/Quickbooks/QuickbooksExport.vue'),
    meta: {
      requiresAuth: true,
      title: 'Quickbooks Export',
      roles: [
        UserRoleEnum.USER,
        UserRoleEnum.ACCOUNTANT,
        UserRoleEnum.MANAGER,
        UserRoleEnum.CHEF,
        UserRoleEnum.GENERAL_MANAGER,
        UserRoleEnum.ADMIN
      ],
      companyTypes: [
        CompanyTypeEnum.RESTAURANT
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL || '/'),
  routes
})

function getStore() {
  return useStore()
}

// Token authentication is now handled in the Auth.vue component

router.beforeEach(async (to, from, next) => {
  const store = getStore()
  
  // Skip auth check for routes that explicitly request it (like the auth route)
  if (to.meta?.skipAuthCheck) {
    return next()
  }
  
  // Handle token in query parameters - redirect to auth component
  if (to.query.token && to.name !== 'auth') {
    return next({
      name: 'auth',
      query: to.query,
      params: to.params
    })
  }

  const isAuthRequired = to.matched.some((record) => record.meta?.requiresAuth)
  const requiredRoles: UserRoleEnum[] = to.meta?.roles as Array<UserRoleEnum> || []
  const requiredCompanyTypes: CompanyTypeEnum[] = to.meta?.companyTypes as Array<CompanyTypeEnum> || []
  const isLoggedIn = !!store.user

  if (isAuthRequired === isLoggedIn) {
    if (
      to.name === getHome(CompanyTypeEnum.RESTAURANT)
      && store.userCompany.type !== CompanyTypeEnum.RESTAURANT
    ) {
      return next({ name: getHome(store.userCompany.type) })
    }

    if (requiredRoles.length) {
      const userCompany = store.userCompany
      const userCompanies = store.companies

      if (
        !userCompany || (
          !requiredRoles.includes(userCompany.userRole)
          && !store.userClaim.admin
          && userCompany.type !== 'admin'
          && (
            !userCompanies
            || !Object.keys(userCompanies).includes(userCompany.adminCompanyId)
          )
        )
      ) {
        const err = new Error('You do not have permissions to view that page')
        await store.setError([err, router])
      }
    }

    if (requiredCompanyTypes.length) {
      const userCompany = store.userCompany

      if (!userCompany || !requiredCompanyTypes.includes(userCompany.type)) {
        const err = new Error('You do not have permissions to view that page')
        await store.setError([err, router])
      }
    }

    return next()
  }

  if (isAuthRequired && !isLoggedIn) {
    return next({ name: 'login' })
  } else {
    return next({ name: getHome(store.userCompany.type) })
  }
})

router.afterEach((to) => {
  document.title = to.meta?.title?.toString() || ''
})

export const navigateToCompanyHome = (company: any | null) => {
  const store = getStore()
  
  if (company && company.id) {
    const route = router.currentRoute
    const routeRoles: UserRoleEnum[] = route.value.meta?.roles as Array<UserRoleEnum> || []
    const userRole = company.userRole

    if (company.type === CompanyTypeEnum.ADMIN) {
      if (route.value.name === 'overview-list') {
        return router.go(0)
      }
      return router.push({ name: 'overview-list' })
    }

    if (company.type === CompanyTypeEnum.VENDOR) {
      if (route.value.name === 'vendor-overview-list') {
        return router.go(0)
      }
      return router.push({ name: 'vendor-overview-list' })
    }

    if (
      (!routeRoles.length || routeRoles.includes(userRole))
      && route.value.meta?.companyIndependent
    ) {
      router.go(0)
    } else {
      return router.push({ name: 'home' })
    }
  }
}

export default router
