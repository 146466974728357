import {defineStore} from 'pinia'
import {Company, IOnboardingPayload, IUserCompany, Order, PriceList, UserRoleEnum} from '@/firestore';
import {Router} from 'vue-router';
import {getHome} from '@/services/navigation.service';

interface State {
    user: any
    userClaim: any
    idToken: string | null
    userCompany: any
    companies: CompanyByKey | null
    onboarding: Partial<IOnboardingPayload> | null
    messages: {
        getUserMedia: boolean
    }
    cache: {
        orderNew: Order | null,
        orderEdit: Order | null,
        priceListEdit: PriceList | null
    }
    error: Error | null
    rememberMe: {
        email: string | null,
        phoneNumber: string | null
    }
    authReady: boolean
}

export interface CompanyByKey {
    [companyId: string]: Company
}

export const useStore = defineStore('store', {
    state: (): State => ({
        user: null,
        userClaim: null,
        idToken: null,
        userCompany: null,
        companies: null,
        onboarding: null,
        messages: {
            getUserMedia: false
        },
        cache: {
            orderNew: null,
            orderEdit: null,
            priceListEdit: null
        },
        error: null,
        rememberMe: {
            email: null,
            phoneNumber: null
        },
        authReady: false
    }),
    actions: {
        updateUser(user: any) {
            this.user = user
        },

        updateUserClaim(userClaim: any) {
            this.userClaim = userClaim
        },

        updateIdToken(idToken: string | null) {
            this.idToken = idToken
        },

        updateCompanies(companies: CompanyByKey | null) {
            this.companies = companies
        },

        updateUserCompany(userCompany: any) {
            this.userCompany = userCompany
        },

        updateOnboarding(onboarding: Partial<IOnboardingPayload> | null) {
            this.onboarding = onboarding
            // Automatically persist onboarding data when updated
            if (onboarding) {
                this.persistOnboardingData()
            }
        },

        persistOnboardingData() {
            // Save to localStorage for recovery in case of browser refresh
            if (this.onboarding) {
                localStorage.setItem('onboardingData', JSON.stringify(this.onboarding))
            }
        },

        loadOnboardingData() {
            // Load from localStorage if available
            const savedData = localStorage.getItem('onboardingData')
            if (savedData) {
                try {
                    this.onboarding = JSON.parse(savedData)
                    return true
                } catch (e) {
                    console.error('Error parsing saved onboarding data', e)
                    localStorage.removeItem('onboardingData')
                }
            }
            return false
        },

        clearOnboardingData() {
            // Clear after successful submission
            localStorage.removeItem('onboardingData')
            this.onboarding = null
        },

        updateMessages(messages: Partial<State['messages']>) {
            this.messages = {...this.messages, ...messages}
        },

        updateCacheOrderNew(order: Order | null) {
            this.cache = {...this.cache, orderNew: order}
        },

        updateCacheOrderEdit(order: Order | null) {
            this.cache = {...this.cache, orderEdit: order}
        },

        updateCachePriceListEdit(priceList: PriceList | null) {
            this.cache = {...this.cache, priceListEdit: priceList}
        },

        updateError(error: Error | null) {
            this.error = error
        },

        setAuthReady(isReady: boolean) {
            this.authReady = isReady
        },

        updateRememberMe(rememberMe: { email: string | null; phoneNumber: string | null }) {
            this.rememberMe = rememberMe
        },

        initializeStore() {
            const storeString = localStorage.getItem('store')
            if (storeString) {
                const parsedStore = JSON.parse(storeString)
                Object.assign(this, parsedStore)
            }
        },

        async setUserCompany(companyParam?: string | Company) {
            const previousUserCompany = this.userCompany
            let userCompany: IUserCompany | null = null
            let adminsCompany: Company | null = null
            let companyId: string | null = null

            if (companyParam && companyParam instanceof Company) {
                adminsCompany = companyParam
            } else if (companyParam) {
                companyId = companyParam
            }

            if (
                adminsCompany
                && adminsCompany.id
                && this.userClaim
                && this.userClaim.admin
            ) {
                userCompany = {
                    ...adminsCompany,
                    userRole: UserRoleEnum.ADMIN
                }
            } else if (this.userClaim && this.companies) {
                const userCompanyId = companyId
                    || (this.userCompany && this.userCompany.id)
                    || this.userClaim.company_id

                let company: Company | null = null;
                if (adminsCompany) {
                    company = adminsCompany
                } else {
                    company = userCompanyId ? this.companies[userCompanyId] : null
                }

                if (company && company.userRoles) {
                    userCompany = {
                        ...company,
                        userRole: company.userRoles[this.userClaim.user_id]
                    }
                }
            }

            if (userCompany && (!this.companies || !this.companies[userCompany.id])) {
                this.updateCompanies({...this.companies, [userCompany.id]: userCompany as Company})
            }

            this.updateUserCompany(userCompany)

            return [userCompany, previousUserCompany]
        },

        setLogin() {
            this.authReady = true
            return true
        },

        addUserToCompany([userId, userRole]: [string, UserRoleEnum]) {
            const users = this.userCompany.users.includes(userId)
                ? this.userCompany.users
                : [...this.userCompany.users, userId]

            const updatedUserCompany = {
                ...this.userCompany,
                userRoles: {
                    ...this.userCompany.userRoles,
                    [userId]: userRole
                },
                users
            }

            this.updateUserCompany(updatedUserCompany)
        },

        setError([error, router]: [Error, Router]) {
            this.updateError(error)
            router.push({name: getHome(this.userCompany.type)})
        }
    },
    getters: {
        // getters
    }
})

// Move the store initialization to a separate function
export function initializeStore() {
    const store = useStore()
    store.$subscribe((_mutation, state) => {
        localStorage.setItem('store', JSON.stringify(state))
    })
    store.initializeStore() // Call the store initialization
}

export default useStore
