import {App} from 'vue'
import {CompanyRepository, ProductCategoryService} from '@/firestore'
import {getFirestore, Firestore as FirebaseFirestore} from "firebase/firestore";

const services = {
  productCategoryService: null as ProductCategoryService | null,
  companyService: null as CompanyRepository | null
}

export const serviceSingletonsPlugin = {
  install(app: App) {
    app.config.globalProperties.productCategoryService = new Proxy({}, {
      get(_target, prop) {
        return async function(...args: any[]) {
          if (!services.productCategoryService) {
            const firebase = await app.config.globalProperties.$firebase;
            const db = getFirestore(firebase) as any;
            services.productCategoryService = new ProductCategoryService(db);
          }
          return services.productCategoryService[prop](...args);
        }
      }
    })

    app.config.globalProperties.companyService = new Proxy({}, {
      get(_target, prop) {
        return async function(...args: any[]) {
          if (!services.companyService) {
            const firebase = await app.config.globalProperties.$firebase;
            const db = getFirestore(firebase) as any;
            services.companyService = new CompanyRepository(db);
          }
          return services.companyService[prop](...args);
        }
      }
    })
  }
}

// Type augmentation
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $productCategoryService: ProductCategoryService
    $companyService: CompanyRepository
  }
}
