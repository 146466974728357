import {BaseMapper} from '../Base/BaseMapper'
import {ICompanyRoles, IUser, User} from './User'
import {UserStatusEnum} from "./UserStatusEnum";
import {DocumentSnapshot} from "firebase/firestore";

export class UserMapper extends BaseMapper<User> {
    fromFirestore(snapshot: DocumentSnapshot): User {
        const data = this.getSnapshotData(snapshot)
        if (!data) throw new Error('No data in DocumentSnapshot')

        const user = new User(snapshot.id, data.companyId, data.phoneNumber)
        user.id = snapshot.id
        user.apiKey = data.apiKey
        user.email = data.email
        user.tz = data.tz
        user.companyId = data.companyId
        user.companyRoles = data?.companyRoles as ICompanyRoles
        user.name = data.name
        user.status = data.status as UserStatusEnum
        user.phoneNumber = data.phoneNumber
        return user
    }

    toFirestore(user: User): Record<string, unknown> {
        return {
            apiKey: user.apiKey,
            companyId: user.companyId,
            email: user.email,
            id: user.id,
            name: user.name,
            phoneNumber: user.phoneNumber,
            status: user.status,
            tz: user.tz,
        }
    }

    map(data: IUser): User {
        const user = new User(data.id, data.companyId, data.phoneNumber)
        user.apiKey = data.apiKey
        user.tz = data.tz
        user.email = data.email
        user.name = data.name
        user.status = data.status
        user.phoneNumber = data.phoneNumber
        user.admin = data.admin

        return user
    }

    getDocumentId(user: User): string | undefined {
        return user.id
    }
}
