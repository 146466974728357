<template>
  <v-app>
    <div v-if="!authReady" class="loading-container">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </div>
    <component v-else :is="(route.meta.layout || 'default') + '-layout'">
      <router-view/>
      <v-dialog persistent :model-value="showGetUserMediaMessage" max-width="50%">
        <v-card>
          <v-card-title class="headline">Warning</v-card-title>
          <v-card-text>
            Your browser version is not compatible with Skilletworks live barcode scanning feature.
          </v-card-text>
          <v-card-text>
            We recommend you either upgrade your browser / OS or use a different browser
            to take full advantage of the barcode scanning feature.
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" @click="updateMessages({ getUserMedia: true })">Ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </component>
  </v-app>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import compatibility from './services/compatibility.service'

const store = useStore()
const route = useRoute()

const authReady = computed(() => store.authReady)
const messagesGetUserMedia = computed(() => store.messages.getUserMedia)

const showGetUserMediaMessage = computed(() => 
  !compatibility.getUserMedia && !messagesGetUserMedia.value
)

const updateMessages = (messages: { getUserMedia: boolean }) => {
  store.updateMessages(messages)
}
</script>

<style lang="scss" scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);
}

/* .text-ellipsis {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.skilletworks-theme .v-main {
  background-color: #eee;
}

.v-application {
  overflow: hidden; 
}

.skilletworks-theme .title-text {
  font-size: 1.25rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.skilletworks-theme .flex-wrap {
  flex-wrap: wrap;
  margin: -10px 0 0 0;
}

.skilletworks-theme .flex-wrap > * {
  margin-top: 10px;
}

.skilletworks-theme .justify-content-flex-start {
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.skilletworks-theme .justify-content-flex-end {
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.skilletworks-theme .action-btn-group > button:not(:first-child) {
  margin-left: 8px;
}

.skilletworks-theme .v-list:not(.v-list--subheader) .panel-header {
  margin-top: -8px;
}

.skilletworks-theme .panel-header.v-subheader {
  color: #fff;
}

.skilletworks-theme .panel-header {
  background-color: #009688;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.v-application .primary--text {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
}

.skilletworks-theme .panel, .skilletworks-theme .raised-panel, .skilletworks-theme .v-list.v-sheet[role=list] {
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.skilletworks-theme .v-application.theme--light, .skilletworks-theme .v-main {
  background-color: #eee;
}

.skilletworks-theme .entry.panel {
  padding-top: 20px;
  padding-bottom: 12px;
}
.skilletworks-theme .panel.row {
  margin-bottom: 14px;
}
.skilletworks-theme .panel.container, .skilletworks-theme .panel.row {
  padding-left: 16px;
  padding-right: 16px;
}
.skilletworks-theme .panel {
  background-color: #fff;
}
.skilletworks-theme .panel, .skilletworks-theme .raised-panel, .skilletworks-theme .v-list.v-sheet[role=list] {
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.skilletworks-theme .panel-header.v-subheader {
  color: #fff;
}

.skilletworks-theme .panel.container .panel-header, .skilletworks-theme .panel.row .panel-header {
  margin-top: -12px;
  margin-left: -16px;
  margin-right: -16px;
}
.skilletworks-theme .panel-header.v-subheader {
  color: #fff;
}
.skilletworks-theme .panel-header {
  background-color: #009688;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
} */
</style>
