import type {Firestore} from 'firebase/firestore';
import {ProductCategory} from './ProductCategory';
import {ProductCategoryRepository} from './ProductCategoryRepository';

export class ProductCategoryService {
    private repository: ProductCategoryRepository;
    private productCategories = new Map<string, ProductCategory>();
    private byParentId = new Map<string, Promise<ProductCategory[]>>();
    private byNameAndLineage = new Map<string, Promise<ProductCategory | null>>();

    constructor(firestore: Firestore, repository?: ProductCategoryRepository) {
        this.repository = repository ? repository : new ProductCategoryRepository(firestore);
    }

    async listProductCategoriesByParentId(id: string): Promise<ProductCategory[]> {
        if (!this.byParentId.has(id)) {
            this.byParentId.set(
                id,
                this.repository
                    .findByParent(id)
                    .then((categories) =>
                        categories.map((category) => this.getOrCacheProductCategory(category)),
                    ),
            );
        }

        return await this.byParentId.get(id) || [];
    }

    async findProductCategoryByNameAndLineage(
        name: string,
        lineage: string[],
    ): Promise<ProductCategory | null> {
        const lookupKey = [...lineage, name].join(' > ');

        if (!this.byNameAndLineage.has(lookupKey)) {
            this.byNameAndLineage.set(
                lookupKey,
                this.repository
                    .findByNameAndLineage(name, lineage)
                    .then((category) => (!category ? null : this.getOrCacheProductCategory(category))),
            );
        }

        return await this.byNameAndLineage.get(lookupKey) || null;
    }

    watchByParentAndHasChildren(parentId: string, hasChildren: boolean) {
        return this.repository.watchQuery((collection, qb) =>
            qb.where('parentId', '==', parentId)
                .where('hasChildren', '==', hasChildren)
                .build()
        );
    }

    private getOrCacheProductCategory(category: ProductCategory): ProductCategory {
        if (this.productCategories.has(category.id)) {
            return this.productCategories.get(category.id)!;
        }

        this.productCategories.set(category.id, category);
        return category;
    }
}
