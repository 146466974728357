<template>
      <v-app-bar :elevation="1" :color="resolveAppBarColor()">
        <v-app-bar-nav-icon @click="showDrawer = !showDrawer"/>
        <v-toolbar-title>
          <a @click="redirectHome" class="text-decoration-none">
            <div v-if="displayCompanyNameText" class="text-h6" :class="{'white--text': isAdmin}">Skilletworks</div>
            <v-layout v-else justify-center align-center>
              <BrandLogo :height="logoHeight"
                        :width="logoWidth"
                        variant="circle"/>
            </v-layout>
          </a>
        </v-toolbar-title>
        <div v-if="isAdmin" class="d-flex justify-center ml-auto mr-5">
          <span class="text-h5 white--text">Administration</span>
        </div>
      </v-app-bar>

      <v-navigation-drawer
        class="app-navigation-drawer"
        location="left"
        :elevation="1"
        :model-value="showDrawer"
        @update:model-value="showDrawer = $event"
        id="nav-drawer"
      >
        <v-list nav density="compact">
          <restaurant-switcher/>
          <template v-for="(navItem, index) in sidebar">
            <v-list-subheader v-if="!navItem.nav" class="text-subtitle-1" :key="index">{{ navItem.title }}</v-list-subheader>
            <v-list-item v-else @click="redirect(navItem.nav)" class="nav-item" :key="navItem.title">
              <template #prepend>
                <v-icon :icon="`mdiSvg:${navItem.icon}`"></v-icon>
              </template>
              <v-list-item-title>
                {{ navItem.title }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>

        <template #append>
          <v-divider class="mb-4" color="rgba(255, 255, 255, 0.12)"/>
          <div class="px-4 pb-4 profile-section">
            <v-row dense align="center">
              <v-col cols="9">
                <div class="text-subtitle-2 text-truncate user-profile">
                  {{ profileDisplay }}
                </div>
              </v-col>
              <v-col cols="3" class="d-flex justify-end">
                <v-btn 
                  icon="mdi-logout" 
                  variant="text"
                  color="white"
                  density="comfortable"
                  @click="logout">
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-navigation-drawer>

      <v-main class="overflow-auto">
        <slot />
      </v-main>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue'
import {RouteMeta, useRouter} from 'vue-router'
import {useDisplay} from 'vuetify'
import {useStore} from '@/store'
import {icons} from '@/composables/icons'
import {useAuth} from '@/composables/useAuth'
import BrandLogo from '@/components/BrandLogo.vue'
import RestaurantSwitcher from '@/components/Company/RestaurantSwitcher.vue'
import {getHome} from '@/services/navigation.service'
import {CompanyTypeEnum} from '@/firestore'
import RestaurantNavBar from '../views/Restaurant/NavBar'
import VendorNavBar from '../views/Vendor/NavBar'
import AdminNavBar from '../views/Admin/Navbar'
import ApplicationAdminNavBar from '../views/ApplicationAdmin/Navbar'
import {getAuth} from "firebase/auth";

const router = useRouter()
const store = useStore()
const auth = useAuth()
const display = useDisplay()

interface NavItem {
  title: string,
  icon?: string,
  nav?: string,
  items?: NavItem[]
}

const showDrawer = ref(true)

const user = computed(() => store.user)
const isAdmin = computed(() => auth.isAdmin)

const displayCompanyNameText = computed(() => {
  return !display.smAndDown.value
})

const logoHeight = computed(() => {
  return '' + ((display.smAndDown.value ? 56 : 64) - 16)
})

const logoWidth = computed(() => {
  return '' + (display.smAndDown.value ? 56 : 64) * 1.334405145
})

const profileDisplay = computed(() => {
  if (!store.userClaim) {
    return ''
  }
  return store.userClaim.name || store.userClaim.email
})

const sidebar = computed(() => {
  const items: NavItem[] = []
  if (auth.isAdmin.value) {
    items.push(...generateNavItems(AdminNavBar))
  } else if (store.userCompany) {
    if (store.userCompany.type === CompanyTypeEnum.RESTAURANT) {
      items.push(...generateNavItems(RestaurantNavBar))
    } else if (store.userCompany.type === CompanyTypeEnum.VENDOR) {
      items.push(...generateNavItems(VendorNavBar))
    }
  }
  return items
})

function redirectHome() {
  if (!store.userCompany) {
    return
  }
  router.push({name: getHome(store.userCompany.type)})
}

function redirect(nav: string) {
  router.push({name: nav})
}

async function logout() {
  await getAuth().signOut()
  // fixme: implement logout to clear session storage
  // await store.logout()
  await router.push({name: 'login'})
}

function generateNavItems(navs: NavItem[]): NavItem[] {
  if (auth.isApplicationAdmin.value || auth.isReadOnlyAdmin.value) {
    navs = [...ApplicationAdminNavBar, ...navs]
  }

  return filterNav(navs)
}

function filterNav(navs: NavItem[]): NavItem[] {
  const routes = router.getRoutes() || []
  const userCompanyUserRole = store.userCompany.userRole
  const userCompanyType = store.userCompany.type

  return navs.filter(navItem => {
    if (navItem.items) {
      navItem.items = filterNav(navItem.items)
      return navItem.items && !!navItem.items.length
    }

    if (navItem.nav) {
      const route = routes.find(r => r.name === navItem.nav)

      if (route) {
        if (route.meta) {
          const meta: RouteMeta = route.meta
          const metaRoles = meta.roles as string[]
          const metaCompanyTypes = meta.companyTypes as string[]

          if (
              metaRoles
              && metaRoles.length
              && !metaRoles.includes(userCompanyUserRole)
              && !auth.isReadOnlyAdmin
              && !auth.isAdminForRestaurant
          ) {
            return false
          }

          if (
              metaCompanyTypes
              && metaCompanyTypes.length
              && !metaCompanyTypes.includes(userCompanyType)
          ) {
            return false
          }
        }
        return true
      }
      return false
    }
    return true
  })
      .filter((item, index, arr) =>
          // filter out any section headers that don't have any items
          !(!item.nav &&
              !item.items &&
              (index === arr.length - 1 || (!arr[index + 1].nav && !arr[index + 1].items)))
      )
}

function filterAccountingNav(navs: any[]) {
  return navs.filter(navItem => navItem.accounting)
}

function resolveAppBarColor() {
  if (auth.isAdmin.value) {
    return 'amber darken-1'
  }
  return 'white'
}
</script>

<style lang="scss" scoped>
#nav-drawer {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.85),
    rgba(0, 0, 0, 0.85)
  ), url("/bg-app-drawer-md.jpg");
  background-size: cover;
  color: #fff;

  .nav-item {
    margin: 4px 12px;
    border-radius: 4px;
    transition: background-color 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.08);
    }
  }

  .v-list-subheader {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0 24px;
    height: 40px;
  }

  .profile-section {
    background: rgba(255, 255, 255, 0.05);
    
    .user-profile {
      color: rgba(255, 255, 255, 0.9);
      font-weight: 400;
      line-height: 1.25;
    }

    .v-btn {
      opacity: 0.8;
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.v-app-bar {
  z-index: 100;
}

.v-main {
  background-color: #eee;
}
</style>
