import type { Firestore } from 'firebase/firestore'
import { BaseRepository } from '../Base/BaseRepository'
import { ProductCategory } from './ProductCategory'
import { ProductCategoryMapper } from './ProductCategoryMapper'

export class ProductCategoryRepository extends BaseRepository<ProductCategory> {
    protected collectionName = 'productCategory'
    protected mapper = new ProductCategoryMapper()

    constructor(firestore: Firestore) {
        super(firestore)
    }

    async findByParent(parentId: string): Promise<ProductCategory[]> {
        return this.query((collection, qb) =>
            qb.where('parentId', '==', parentId)
              .build()
        )
    }

    async findByParentAndHasChildren(parentId: string, hasChildren: boolean): Promise<ProductCategory[]> {
        return this.query((collection, qb) =>
            qb.where('parentId', '==', parentId)
              .where('hasChildren', '==', hasChildren)
              .build()
        )
    }

    async findByNameAndLineage(name: string, lineage: string[]): Promise<ProductCategory | null> {
        const categories = await this.query((collection, qb) =>
            qb.where('name', '==', name)
              .where('lineage', '==', lineage)
              .limit(1)
              .build()
        )
        return categories[0] || null
    }

    async findAll(): Promise<ProductCategory[]> {
        return this.query((collection, qb) => qb.build())
    }

    watch(id: string): { data: ProductCategory | null; unsub: () => void } {
        return super.watch(id)
    }

    watchByRestaurantAndVendor(restaurantId: string, vendorId: string) {
        return this.watchQuery((collection, qb) =>
            qb.where('restaurantCompanyId', '==', restaurantId)
              .where('vendorCompanyId', '==', vendorId)
              .build()
        )
    }

    async findByRestaurantAndVendor(
        restaurantId: string,
        vendorId: string,
    ): Promise<ProductCategory[]> {
        return this.query((collection, qb) =>
            qb.where('restaurantCompanyId', '==', restaurantId)
              .where('vendorCompanyId', '==', vendorId)
              .build()
        )
    }

    async save(category: ProductCategory): Promise<ProductCategory> {
        category.name = category.name.trim()
        return this.upsert(category)
    }

    async deleteProductCategory(productCategory: ProductCategory): Promise<void> {
        if (productCategory.id) {
            return this.delete(productCategory.id)
        }
    }
}
