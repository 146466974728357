// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import { createVuetify } from 'vuetify'
import { mdi } from 'vuetify/iconsets/mdi'
import { aliases, mdi as mdiSvg } from 'vuetify/iconsets/mdi-svg'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { ThemeDefinition } from 'vuetify'

const light: ThemeDefinition = {
  dark: false,
  colors: {
    success: '#009688', // teal base
    error: '#e91e63',   // pink base
    primary: '#1976D2',
    secondary: '#424242',
    accent: '#82B1FF',
    info: '#2196F3',
    warning: '#FB8C00',
    'on-success': '#FFFFFF',
    'on-error': '#FFFFFF'
  }
}

export default createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      mdiSvg
    }
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light
    }
  },
  defaults: {
    VBtn: {
      variant: 'elevated',
      style: 'text-transform: none;'
    },
    VCard: {
      variant: 'elevated'
    }
  }
})
