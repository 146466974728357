import {Address, CompanyStatusEnum, CompanyTypeEnum, FeatureEnum, ProductTypeEnum, UserRoleEnum} from '@/firestore'

export interface IUserRoles {
  [uid: string]: UserRoleEnum
}

export interface IExternalIds {
  sevenFifty?: number
}

export interface ICompany {
  id: string
  name: string
  identifier?: string
  address?: Address
  type: CompanyTypeEnum
  status: CompanyStatusEnum
  users: string[]
  admins: string[]
  userRoles: IUserRoles
  features: FeatureEnum[]
  preferred: boolean
  adminCompanyId?: string
  productTypes: ProductTypeEnum[]
  subscriptionId?: number
  restaurantLimit?: number
  externalIds: IExternalIds
  createdAt: Date
  updatedAt: Date
}

export class Company implements ICompany {
  public id: string
  public identifier?: string
  public address?: Address
  public status: CompanyStatusEnum = CompanyStatusEnum.ACTIVE
  public users: string[] = []
  public admins: string[] = []
  public userRoles: IUserRoles = {}
  public features: FeatureEnum[] = []
  public preferred: boolean = false
  public adminCompanyId?: string
  public productTypes: ProductTypeEnum[] = []
  public subscriptionId?: number
  public restaurantLimit?: number
  public externalIds: IExternalIds = {}
  public createdAt: Date
  public updatedAt: Date

  constructor(public name: string, public type: CompanyTypeEnum) {
    this.id = ''
    this.createdAt = new Date()
    this.updatedAt = new Date()
  }

  public get displayName() {
    if (this.identifier) {
      return `${this.name} (${this.identifier})`
    } else if (this.address && this.address.state) {
      return `${this.name} (${this.address.state})`
    } else {
      return this.name
    }
  }

  public get effectiveRestaurantLimit() {
    return typeof this.restaurantLimit === 'number'
      ? this.restaurantLimit
      : -1
  }
}

export interface IUserCompany extends ICompany {
  userRole?: string
}
