import {home as vendorHome} from '@/views/Vendor/NavBar'
import {home as restaurantHome} from '@/views/Restaurant/NavBar'
import {home as adminHome} from '@/views/Admin/Navbar'
import {CompanyTypeEnum} from '@/firestore'

export const getHome = (companyType?: CompanyTypeEnum) => {
  if (companyType === CompanyTypeEnum.ADMIN) {
    return adminHome
  } else if (companyType === CompanyTypeEnum.VENDOR) {
    return vendorHome
  }
  return restaurantHome
}
