import {collection, DocumentSnapshot, Firestore, onSnapshot, query, where} from 'firebase/firestore'
import {BaseRepository, Company, CompanyMapper, CompanyStatusEnum, CompanyTypeEnum} from '@/firestore'
import {db} from '@/plugins/firebase'

export class CompanyRepository extends BaseRepository<Company> {
    protected collectionName = 'company'
    protected mapper = new CompanyMapper()

    constructor(firestore: Firestore) {
        super(firestore)
    }

    // Watching methods
    watchByType(type: CompanyTypeEnum, onUpdate: (companies: Company[]) => void) {
        const companyRef = collection(db, this.collectionName)
        const q = query(companyRef, where('type', '==', type))
        const unsub = onSnapshot(q, (snapshot) => {
            const mappedCompanies = snapshot.docs.map(doc => this.mapper.fromFirestore({
                id: doc.id,
                data: () => doc.data(),
                exists: () => true
            } as DocumentSnapshot))
            onUpdate(mappedCompanies)
        }, (error) => {
            console.error('Error in company watcher:', error)
        })
        
        return { unsub }
    }

    watchByCompanyId(companyId: string, onUpdate: (companies: Company[]) => void) {
        const companyRef = collection(db, this.collectionName)
        const q = query(companyRef, where('id', '==', companyId))
        const unsub = onSnapshot(q, (snapshot) => {
            const mappedCompanies = snapshot.docs.map(doc => 
                this.mapper.fromFirestore({
                    id: doc.id,
                    data: () => doc.data(),
                    exists: () => true
                } as DocumentSnapshot))
            onUpdate(mappedCompanies)
        }, (error) => {
            console.error('Error in company watcher:', error)
        })
        
        return { unsub }
    }

    watchByAdminCompany(adminCompanyId: string) {
        return this.watchQuery((collection, qb) =>
            qb.where('adminCompanyId', '==', adminCompanyId)
              .build()
        )
    }

    watchById(id: string): { data: Company | null; unsub: () => void } {
        return this.watch(id)
    }

    // List methods
    async list(order?: [string, 'asc' | 'desc'][]): Promise<Company[]> {
        if (!order?.length) return this.findAll()

        return this.query((collection, qb) => {
            order.forEach(([field, direction]) => {
                qb.orderBy(field, direction)
            })
            return qb.build()
        })
    }

    async findByType(type: CompanyTypeEnum): Promise<Company[]> {
        return this.query((collection, qb) =>
            qb.where('type', '==', type)
              .build()
        )
    }

    async findActiveByType(type: CompanyTypeEnum): Promise<Company[]> {
        return this.query((collection, qb) =>
            qb.where('type', '==', type)
              .where('status', '==', CompanyStatusEnum.ACTIVE)
              .build()
        )
    }

    async findByUser(userId: string): Promise<Company[]> {
        const [userCompanies, adminCompanies] = await Promise.all([
            this.query((collection, qb) =>
                qb.where('users', 'array-contains', userId)
                  .build()
            ),
            this.query((collection, qb) =>
                qb.where('admins', 'array-contains', userId)
                  .build()
            ),
        ])

        const uniqueCompanies = new Map<string, Company>()
        Array.from([...userCompanies, ...adminCompanies]).forEach(company =>
            uniqueCompanies.set(company.id, company),
        )

        return Array.from(uniqueCompanies.values())
    }

    async findByField(field: string, value: string, activeOnly = false): Promise<Company[]> {
        return this.query((collection, qb) => {
            qb.where(field, '==', value)
            if (activeOnly) {
                qb.where('status', '==', CompanyStatusEnum.ACTIVE)
            }
            return qb.build()
        })
    }

    async findByAdminCompany(adminCompanyId: string, activeOnly = false): Promise<Company[]> {
        return this.findByField('adminCompanyId', adminCompanyId, activeOnly)
    }

    async findBySevenFiftyId(sevenFiftyId: number): Promise<Company | null> {
        const companies = await this.query((collection, qb) =>
            qb.where('externalIds.sevenFifty', '==', sevenFiftyId)
              .limit(1)
              .build()
        )
        return companies[0] || null
    }

    async findVendorBySevenFiftyId(sevenFiftyId: number): Promise<Company | null> {
        const companies = await this.query((collection, qb) =>
            qb.where('type', '==', CompanyTypeEnum.VENDOR)
              .where('externalIds.sevenFifty', '==', sevenFiftyId)
              .limit(1)
              .build()
        )
        return companies[0] || null
    }

    async findBySubscriptionId(subscriptionId: number): Promise<Company | null> {
        const companies = await this.query((collection, qb) =>
            qb.where('subscriptionId', '==', subscriptionId)
              .limit(1)
              .build()
        )
        return companies[0] || null
    }

    async save(company: Company): Promise<Company> {
        company.name = company.name.trim()
        if (company.identifier) {
            company.identifier = company.identifier.trim()
        }
        return this.upsert(company)
    }

    async deleteCompany(company: Company): Promise<void> {
        if (company.id) {
            return this.delete(company.id)
        }
    }
}