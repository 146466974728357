<template>
  <svg data-v-63ff88a9="" data-v-6aeb16f8=""
       xmlns="http://www.w3.org/2000/svg" fill="none"
       viewBox="0 0 951 690" :height="svgHeight" :width="svgWidth">
    <path data-v-63ff88a9="" fill="#222"
          d="M 345,0 A 345,345 0 0 0 0,345 345,345 0 0 0 345,690 345,345 0 0 0 690,345 345,345 0 0 0 345,0 Z m -0.4707,17 A 328,328 0 0 1 345,17 328,328 0 0 1 673,345 328,328 0 0 1 345,673 328,328 0 0 1 17,345 328,328 0 0 1 344.5293,17 Z"
          class="color-main"></path>
    <path data-v-63ff88a9="" fill="#222"
          d="M 951,305.5 C 858.967,322.522 806.22,325.947 709.5,318 l -2.23047,-0.0332 V 368.5 H 709.5 c 97.624,-8.211 150.692,-6.527 241.5,11 z M 935,327 v 33 c -19.908,-5.71 -31.29,-8.097 -52.5,-9 v -17 c 20.993,-0.957 32.399,-2.809 52.5,-7 z"
          class="color-main"></path>
    <path data-v-63ff88a9="" fill="#222"
          d="m 548,292.5 c 6.234,39.531 6.383,61.438 0,100 35.227,-12.025 55.419,-17.46 92.5,-24 h 13.85352 V 317.20117 L 640.5,317 C 602.236,311.501 582.175,305.771 548,292.5 Z"
          class="color-main"></path>
    <path data-v-63ff88a9="" fill="#222"
          d="m 190.5,386.5 c 0,-4.694 3.8056,-8.5 8.5,-8.5 h 291 c 4.694,0 8.5,3.806 8.5,8.5 0,4.694 -3.806,8.5 -8.5,8.5 H 199 c -4.6944,0 -8.5,-3.806 -8.5,-8.5 z"
          class="color-main"></path>
    <path data-v-63ff88a9="" fill="#222"
          d="m 199,292 c -4.6944,0 -8.5,3.806 -8.5,8.5 0,4.694 3.8056,8.5 8.5,8.5 h 291 c 4.694,0 8.5,-3.806 8.5,-8.5 0,-4.694 -3.806,-8.5 -8.5,-8.5 z"
          class="color-main"></path>
    <path data-v-63ff88a9="" fill="#222"
          d="m 500.035,218.9747 c 17.419,14.2813 25.645,35.9183 27.447,63.4703 0.012,0.185 0.018,0.37 0.018,0.555 v 119.5 0.035 c -0.119,29.077 -9.498,50.344 -27.962,64.066 -17.928,13.323 -43.045,18.459 -72.411,18.898 -4.694,0.07 -8.556,-3.678 -8.626,-8.372 -0.07,-4.694 3.678,-8.556 8.372,-8.626 28.102,-0.42 48.874,-5.4 62.524,-15.545 13.111,-9.743 20.997,-25.293 21.103,-50.476 V 283.281 c -1.665,-24.779 -8.855,-41.003 -21.243,-51.16 -12.647,-10.368 -32.361,-15.7965 -62.064,-15.1232 -0.039,8e-4 -0.078,0.002 -0.117,0.002 l -0.045,3e-4 H 427 256.334 c -23.611,1.8782 -41.899,7.5295 -54.6058,17.7511 -12.404,9.979 -20.5323,25.161 -22.2282,48.551 v 119.198 c 0,0.025 -1e-4,0.05 -3e-4,0.076 -0.216,24.28 6.2558,40.185 17.6723,50.218 11.6527,10.24 30.0666,15.808 56.795,15.706 H 254 393.5 c 4.694,0 8.5,3.806 8.5,8.5 0,4.694 -3.806,8.5 -8.5,8.5 H 254.016 c -28.6412,0.107 -51.9511,-5.775 -68.0657,-19.936 -16.3461,-14.364 -23.6861,-35.801 -23.4503,-63.101 v -119.463 c 0,-0.198 0.007,-0.396 0.0208,-0.594 1.909,-27.265 11.6861,-47.333 28.5517,-60.9006 16.5559,-13.3184 38.8787,-19.5017 64.2675,-21.4798 0.219,-0.0171 0.44,-0.0257 0.66,-0.0257 h 170.904 c 31.404,-0.6992 55.942,4.8825 73.131,18.9748 z"
          class="color-main"></path>
    <path data-v-63ff88a9="" fill="#222"
          d="m 222,250 c -4.6944,0 -8.5,3.806 -8.5,8.5 0,4.694 3.8056,8.5 8.5,8.5 h 243 c 4.694,0 8.5,-3.806 8.5,-8.5 0,-4.694 -3.806,-8.5 -8.5,-8.5 z"
          class="color-main"></path>
    <path data-v-63ff88a9="" fill="#222"
          d="m 212.5,429.5 c 0,-4.694 3.8056,-8.5 8.5,-8.5 h 243 c 4.694,0 8.5,3.806 8.5,8.5 0,4.694 -3.806,8.5 -8.5,8.5 H 221 c -4.6944,0 -8.5,-3.806 -8.5,-8.5 z"
          class="color-main"></path>
    <path data-v-63ff88a9="" fill="#222"
          d="m 198,336 c -4.6944,0 -8.5,3.806 -8.5,8.5 0,4.694 3.8056,8.5 8.5,8.5 h 291 c 4.694,0 8.5,-3.806 8.5,-8.5 0,-4.694 -3.806,-8.5 -8.5,-8.5 z"
          class="color-main"></path>
    <path data-v-63ff88a9="" fill="#f00" d="m 469.99989,129.49989 v 30 h 60 v 60 h 30 v -90 z"
          class="color-accent"></path>
    <path data-v-63ff88a9="" fill="#f00" d="m 559.99989,470.49989 h -30 v 60 h -60 v 30 h 90 z"
          class="color-accent"></path>
    <path data-v-63ff88a9="" fill="#f00" d="m 219.99989,560.49989 v -30 h -60 v -60 h -30 v 90 z"
          class="color-accent"></path>
    <path data-v-63ff88a9="" fill="#f00" d="m 129.99989,219.49989 h 30 v -60 h 60 v -30 h -90 z"
          class="color-accent"></path>
  </svg>
</template>

<script setup lang="ts">
import {computed} from 'vue'

interface SizingOption {
  height: string
  width: string
}

interface Sizing {
  default: SizingOption
  circle: SizingOption
}

const props = defineProps({
  variant: {
    type: String as () => 'default' | 'circle',
    default: 'default'
  },
  colorMain: {
    type: String,
    default: '#222'
  },
  colorAccent: {
    type: String,
    default: '#f00'
  },
  width: {
    type: String,
    default: ''
  },
  height: {
    type: String,
    default: ''
  }
})

const sizing: Sizing = {
  default: {
    height: '431',
    width: '821'
  },
  circle: {
    height: '690',
    width: '951'
  }
}

const svgWidth = computed(() => {
  if (props.width) {
    return props.width
  }
  if (props.height) {
    return 'auto'
  }
  return sizing[props.variant].width
})

const svgHeight = computed(() => {
  if (props.height) {
    return props.height
  }
  if (props.width) {
    // Use a percentage value instead of 'auto' for SVG height
    return '100%'
  }
  return sizing[props.variant].height
})
</script>
