import {
  mdiClipboardText,
  mdiChartBar
} from '@mdi/js'

export const home = 'vendor-overview-list'

export default [
  { title: 'Vendor' },
  { title: 'Overview', icon: mdiChartBar, nav: 'vendor-overview-list' },
  { title: 'Orders', icon: mdiClipboardText, nav: 'vendor-order-list' }
]
