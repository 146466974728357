import {BaseMapper, ProductCategory} from '@/firestore'
import {DocumentSnapshot} from 'firebase/firestore';

export class ProductCategoryMapper extends BaseMapper<ProductCategory> {
    fromFirestore(snapshot: DocumentSnapshot): ProductCategory {
        const data = snapshot.data()
        if (!data) throw new Error('No data in DocumentSnapshot')

        const category = new ProductCategory()
        category.id = snapshot.id
        category.name = data.name
        category.parentId = data.parentId
        category.lineage = data.lineage
        category.hasChildren = data.hasChildren

        return category
    }

    toFirestore(category: ProductCategory): Record<string, unknown> {
        return {
            id: category.id,
            name: category.name,
            parentId: category.parentId,
            lineage: category.lineage,
            hasChildren: category.hasChildren,
        }
    }

    getDocumentId(category: ProductCategory): string | undefined {
        return category.id
    }
}
