import {
  mdiAccountMultiple,
  mdiCurrencyUsd,
  mdiBriefcase,
  mdiChartBar
} from '@mdi/js'

export const home = 'overview-list'

export default [
  { title: 'Management' },
  { title: 'Overview', icon: mdiChartBar, nav: 'overview-list' },
  { title: 'Restaurants', icon: mdiBriefcase, nav: 'restaurant-list' },
  { title: 'Settings' },
  { title: 'User Management', icon: mdiAccountMultiple, nav: 'user-list' }
]
