import {computed} from 'vue'
import {useStore} from '@/store'
import {AdminRoleEnum, CompanyTypeEnum, UserRoleEnum} from '@/firestore'

// Create a function to get store instance
function getStore() {
  return useStore()
}

// Wrap computed properties in a function that returns an object of computeds
export function useAuth() {
  const store = getStore()

  return {
    isApplicationAdmin: computed(() =>
      store.userClaim?.admin === AdminRoleEnum.APPLICATION_ADMIN
    ),

    isReadOnlyAdmin: computed(() =>
      store.userClaim?.admin === AdminRoleEnum.READ_ONLY_ADMIN
    ),

    isAdmin: computed(() =>
      store.userClaim &&
      store.userCompany?.type === CompanyTypeEnum.ADMIN
    ),

    isAdminForRestaurant: computed(() =>
      store.userClaim &&
      store.userCompany &&
      (store.userCompany.type === CompanyTypeEnum.ADMIN ||
        (store.companies &&
          store.userCompany.adminCompanyId &&
          store.companies[store.userCompany.adminCompanyId]))
    ),

    isAdminRole: computed(() =>
      store.userClaim &&
      store.userCompany?.type === CompanyTypeEnum.ADMIN
    ),

    isVendor: computed(() =>
      store.userClaim && (
        (store.userCompany?.type === CompanyTypeEnum.VENDOR) ||
        store.userClaim &&
        store.userCompany?.type === CompanyTypeEnum.ADMIN
      )
    ),

    isRestaurant: computed(() =>
      store.userClaim && (
        (store.userCompany?.type === CompanyTypeEnum.RESTAURANT) ||
        store.userClaim &&
        store.userCompany?.type === CompanyTypeEnum.ADMIN
      )
    ),

    isSuperUser: computed(() =>
      store.userClaim &&
      store.userCompany?.type === CompanyTypeEnum.ADMIN &&
      store.userClaim &&
      store.userCompany?.type === CompanyTypeEnum.ADMIN
    ),

    isGeneralManager: computed(() =>
      store.userClaim && (
        (store.userCompany?.userRole === UserRoleEnum.GENERAL_MANAGER) ||
        store.userClaim &&
        store.userCompany?.type === CompanyTypeEnum.ADMIN
      )
    ),

    isChef: computed(() =>
      store.userClaim && (
        (store.userCompany?.userRole === UserRoleEnum.CHEF) ||
        store.userClaim &&
        store.userCompany?.type === CompanyTypeEnum.ADMIN
      )
    ),

    isManager: computed(() =>
      store.userClaim && (
        (store.userCompany?.userRole === UserRoleEnum.MANAGER) ||
        store.userClaim &&
        store.userCompany?.type === CompanyTypeEnum.ADMIN
      )
    ),

    isAccountant: computed(() =>
      store.userClaim && (
        (store.userCompany?.userRole === UserRoleEnum.ACCOUNTANT) ||
        store.userClaim &&
        store.userCompany?.type === CompanyTypeEnum.ADMIN
      )
    ),

    isUser: computed(() =>
      store.userClaim && (
        (store.userCompany?.userRole === UserRoleEnum.USER) ||
        store.userClaim &&
        store.userCompany?.type === CompanyTypeEnum.ADMIN ||
        store.userClaim &&
        store.userClaim?.admin === AdminRoleEnum.READ_ONLY_ADMIN
      )
    ),

    isGeneralManagerOrGreater: computed(() =>
      store.userClaim && (
        (store.userCompany && (
          store.userCompany?.userRole === UserRoleEnum.GENERAL_MANAGER ||
          store.userCompany?.userRole === UserRoleEnum.CHEF
        )) ||
        store.userClaim &&
        store.userCompany?.type === CompanyTypeEnum.ADMIN
      )
    ),

    isChefOrGreater: computed(() =>
      store.userClaim && (
        (store.userCompany && (
          store.userCompany?.userRole === UserRoleEnum.GENERAL_MANAGER ||
          store.userCompany?.userRole === UserRoleEnum.CHEF
        )) ||
        store.userClaim &&
        store.userCompany?.type === CompanyTypeEnum.ADMIN
      )
    ),

    isManagerOrGreater: computed(() =>
      store.userClaim && (
        (store.userCompany && (
          store.userCompany?.userRole === UserRoleEnum.MANAGER ||
          store.userCompany?.userRole === UserRoleEnum.GENERAL_MANAGER
        )) ||
        store.userClaim &&
        store.userCompany?.type === CompanyTypeEnum.ADMIN
      )
    ),

    isUserOrGreater: computed(() =>
      store.userClaim && (
        (store.userCompany && (
          store.userCompany?.userRole === UserRoleEnum.USER ||
          store.userCompany?.userRole === UserRoleEnum.MANAGER
        )) ||
        store.userClaim &&
        store.userCompany?.type === CompanyTypeEnum.ADMIN ||
        store.userClaim &&
        store.userClaim?.admin === AdminRoleEnum.READ_ONLY_ADMIN
      )
    )
  }
}
