import {createApp} from 'vue'
import {createPinia} from 'pinia'
import {initializeStore} from '@/store'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import NoHeaderLayout from '@/layouts/NoHeaderLayout.vue'
import {firebasePlugin} from './plugins/firebase'
import {filtersPlugin} from './plugins/filters'
import {serviceSingletonsPlugin} from "@/plugins/service-singletons";

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)
initializeStore()
app.use(router)
app.use(serviceSingletonsPlugin)
app.use(vuetify)
app.use(firebasePlugin)
app.use(filtersPlugin)
// app.use(sentryPlugin)

app.component('default-layout', DefaultLayout)
app.component('no-header-layout', NoHeaderLayout)

/*app.use(VueTagManager, {gtmId: process.env.VUE_APP_GOOGLE_TAG_MANAGER})*/

app.mount('#app')
